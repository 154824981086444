<template>
  <section class="section section--with-padding our-apps">
    <template v-for="(item, index) in section.items" :key="index">
      <h2 v-if="item.title">
        {{
          $rt(item.title, {
            brand: brandWithCapitalizeFirstLetter,
          })
        }}
      </h2>
      <p v-if="item.text" class="our-apps__text">
        {{ $rt(item.text) }}
      </p>
      <ScrollSlider v-if="item.items?.length" :items="item.items">
        <template #scroll-slider-item="{ item }">
          <p>
            {{ $rt(item.item.description) }}
          </p>
          <div>
            <LocLink
              v-if="!checkAPKFile(item.item.link)"
              :to="$rt(item.item.link)"
              target="_blank"
            >
              {{ $t("Download") }}
            </LocLink>
            <LocLink v-else :to="$rt(item.item.link)" download>
              {{ $t("Download") }}
            </LocLink>
            <BaseIcon icon="arrow" />
          </div>
        </template>
      </ScrollSlider>
    </template>
  </section>
</template>

<script setup lang="ts">
import ScrollSlider from "~/components/slider/ScrollSlider.vue";
import LocLink from "~/components/lang/LocLink.vue";
import BaseIcon from "~/components/icons/BaseIcon.vue";

interface IOurAppsSectionItem {
  title: string;
  text: string;
  items: {
    description: string;
    link: string;
  }[];
}

interface IOurAppsSection {
  items: IOurAppsSectionItem[];
}

const {
  public: { brandWithCapitalizeFirstLetter },
} = useRuntimeConfig();
const { rt, tm } = useI18n();

const section = tm("our_apps_section") as IOurAppsSection;

function checkAPKFile(value: string): boolean {
  return rt(value).split(".").pop() === "apk";
}
</script>
